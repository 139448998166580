@import '../node_modules/@gsp/gusto-front-common/dist/scss/typography';
@import '../node_modules/@gsp/gusto-front-common/dist/scss/print';
@import '../node_modules/@gsp/gusto-front-common/dist/scss/ie-fix.scss';

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: Allianz, Arial, sans-serif;
  min-height: 100%;
  line-height: 1.5 !important;
}

* {
  -webkit-overflow-scrolling: touch;
}
