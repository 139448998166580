html, #root, body {
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Allianz';
  src: url("../node_modules/@gsp/gusto-front-common/dist/scss/font/AllianzNeo-Light.ttf"); }

  @font-face {
  font-family: 'AllianzBold';
  src: url("../node_modules/@gsp/gusto-front-common/dist/scss/font/Applied Sans Pro Cn UltraBlack.otf"); }

input[type="text"] {
  outline: 0;
}

ul {
  padding-inline-start: 0px;
}

a {
  color: inherit;
  text-decoration: inherit;
}

button {
  line-height: normal;
}
