.quotation-type {
    display: flex;
    justify-content: space-between;
}

.quotation-type__title {
    margin:auto;
    margin-bottom: 1.5rem;
    font-size: 18px;
    color: #002F3C; 
    font-weight: 600;
    width: 80%;
}

.quotation-type__option--left {
    flex: 1;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.quotation-type__option--right {
    flex: 1;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.quotation-type__description--left {
    margin-top: 1rem;
    max-width: 20rem;
    text-align: right;
    color: rgba(0,0,0,.26);
}

.quotation-type__description--right {
    margin-top: 1rem;
    max-width: 20rem;
    text-align: left;
    color: rgba(0,0,0,.26);
}

.quotation-type__unavailable {
    display: flex;
    flex: 1;
    max-width: 210px;
    align-items: center;
    justify-content: center;
}

.quotation-type__arrow {
    margin-right: 0.3rem;
    fill: #2768a7;
    transform: scale(0.8);
}

.quotation-type__link {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    color: #2768a7;
}

.quotation-type__not-found {
    font-weight: 600;
    margin-top: 2rem;
    font-size: 12px;
    color: rgba(0,0,0,.26);
}

.quotation-estimation__confirmation-disclaimer{
    font-size: 18px !important;
}
.email-recap{
    font-size: 16px !important;
}

.summary-details {
    font-size: 0.9em;
}