@import '../../../../../../node_modules/@gsp/gusto-front-common/dist/scss/_reponsive.scss';

.quotation-estimation {
    font-size: 22px;
    flex: 4rem 0;
}

.quotation-estimation--separator {
    border-top: none;
    border-bottom: 1px solid #c5c5c5;
    max-width: 20rem;
    margin: 1rem auto;
}
.quotation-estimation__actions {
    margin-top: 1rem;
    display: flex;
    width: 100%;
    justify-content: space-around;
    justify-content: space-evenly;
}

.quotation-estimation__change-type {
    flex-grow: 0;
    margin-top: 1rem;
    text-decoration: underline;
    cursor: pointer;
    color: #2768a7;
}

.quotation-estimation__disclaimer {
    margin-top: 1rem;
    font-size: 14px;
    color: rgba(0,0,0,.26);
}

.quotation-estimation__confirmation {
    font-size: 22px;
}


.quotation-estimation__confirmation-disclaimer {
    font-size: 14px;
}

.quotation-estimation__error {
    color: red;
    margin-bottom: 1rem;
    font-weight: 600;
}

.price-indication-button {
    flex: 4rem 0;
    margin-top: 2rem;
}

@media ($tablet) {
    .quotation-estimation__confirmation {
        max-width: 50rem;
        /* margin-top: 2rem; */
    }
}
