.guarantee-explanation {
    display: flex;
    justify-content: center;
    background: rgba(236, 234, 229, 0.4);
    width: 100%;
}

.guarantee-explanation__container {
    align-self: center;
    text-align: left;
    color: white;
    background: #1F5385;
    padding: 1rem 2rem;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.375rem;
}

.guarantee-explanation__title {
    color: white;
    font-size: 1.25rem;
    font-weight: bold;
}
