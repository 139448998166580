@import '../../../../node_modules/@gsp/gusto-front-common/dist/scss/reponsive';
@import '../../../../node_modules/@gsp/gusto-front-common/dist/scss/colors';

.header {
    display: flex;
    flex: 0 0 4rem;
    align-items: center;
    justify-content: space-between;
    font-weight: 300;
    color: #2768a7;
    padding: .5rem 1rem;
    box-sizing: border-box;
    width: 100%;
}

.right_part {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* width: 20%; */
}

.left_part{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
}

@media print {
    .header {
        display: none;
    }
}

.link {
    margin: 0 1rem;
    text-decoration: none;
}

.link:visited {
    color: #2768a7;
}

.header__separator {
    margin: 0;
    width: 100%;
    border-top-color: rgba(0, 0, 0, 0.1);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: initial;
    border-right-style: initial;
    border-right-width: 0px;
    border-bottom-color: initial;
    border-bottom-style: initial;
    border-bottom-width: 0px;
    border-left-color: initial;
    border-left-style: initial;
    border-left-width: 0px;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
}

.header__logo {
    width: 150px;
    height: auto;
}

.header-coc-logo {
    height: 45px;

    @media($mobile-only) {
        height: 35px;
    }
    @media($tablet) {
        margin-left: 2rem;
    }
}

.header-lang-toggle {
    margin-left: 0.5rem;
    flex: 0 0 1.5rem;
    text-align: left;
}

.header-lang-toggle .button-header-secondary {
    min-width: 50px;
}

.header__icon-link {
    display: flex;
    align-items: center;
}

.header__login {
    display: flex;
    margin-right: 0.5rem;
}

.header__burger {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    justify-content: space-evenly;
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
}

.header__burger-stripe,
.header__burger-stripe::before,
.header__burger-stripe::after {
    height: 3px;
    width: 1.5rem;
    background: #2768a7;
}

.header__burger-stripe--isCross {
    background-color: transparent;
}

.header__burger-stripe::before,
.header__burger-stripe::after {
    content:'';
    display: block;
    transform-origin: 50% 0;
    transition: transform .3s ease;
}

.header__burger-stripe::before {
    transform: translate3d(0,-8px,0);
}

.header__burger-stripe::after {
    transform: translate3d(0,5px,0);
}

.header__burger-stripe--isCross::before {
    transform: translate3d(0,0,0) rotate(45deg);
}

.header__burger-stripe--isCross::after {
    transform: translate3d(0,0,0) rotate(135deg);
}

.header__mobile-nav {
    transition: transform 0.5s ease-in-out,
      height 0.5s ease-in-out,
      opacity 1s;
    flex-direction: column;
    justify-content: space-around;
    justify-content: space-evenly;
    align-items: center;
    transform: translateY(-10rem);
    height: 0rem;
    visibility: hidden;
    display: flex;
}

.header__mobile-nav--toggled {
    z-index: 1;
    transform: translateY(0rem);
    visibility: visible;
    height: 6.5rem;
    opacity: 1;
}
.header__mobile-nav--hidden {
    opacity: 0;
}

.header__navigation button.button-header-secondary > div {
    font-size: 0.8em;
}

.header__navigation button.button-header-secondary {
    &:not(.ata-header-button) { //TODO fix this at some point
        height:3rem;
    }

    margin-right:5px;
}

.ata-header {
    @media($mobile-only) {
        flex-direction: column;
    }

    .header__burger {
        margin-top: 1rem;
    }
}

.header__mobile-nav--ata.header__mobile-nav--toggled {
    height: 9rem;

    .language-selector {
        margin: initial;
    }
}

.header__disclaimer-container {
    flex-basis: 100%;
    text-align: start;
    color: $blue-allianz-trade;
    white-space: nowrap;
    font-size: smaller;
}
