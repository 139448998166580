@import '../../../../node_modules/@gsp/gusto-front-common/dist/scss/reponsive';

.footer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    justify-content: space-evenly;
    background-color: #414141;
    width: 100%;
    min-height: 6rem;
}

@media print {
    .footer {
        display: none;
    }
}

@media ($mobile-only) {
    .footer {
        min-height: 8rem;
        flex-direction: column;
    }
}


.footer__social-networks {
    display: flex;
    width: 12rem;
    justify-content: space-between;
}

.footer__links {
    display: flex;
    justify-content: space-between;

    @media ($mobile-only) {
        flex-direction: column;
    }
}


.footer__logo {
    width: 200px;
}

.footer__link {
    font-weight: bold;
    color: white;
    margin-right: 1rem;
}

a.footer__link:hover {
    text-decoration: underline;
}

.footer--fix-ie-align-center {
    height: 6rem;
    width: 0;
    opacity: 0;
}
