.our-offer {
    display: flex;
    justify-content: center;
    background: white;
}

.our-offer__title {
    font-weight: bold;
    color: white;
    font-size: 1.25rem;
}

.our-offer__container {
    padding: 1rem 2rem;
    align-self: center;
    text-align: left;
    color: white;
    background: #2768A7;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.375rem;
}

.our-offer__type {
    font-size: 1.25rem;
    font-weight: bold;
}

.our-offer li {
    list-style: none; 
    font-weight: bold;
}

.our-offer li ::before {
    content: "\25A0";
    font-weight: 600;
    font-size: 10px;
    font-size: .625rem;
    padding-right: 8px;
    position: relative;
    top: -3px;
    color: white;
}

.our-offer__list {
    border-left: 5px solid white;
    color: #7a7a7a;
    font-size: 1.3rem;
    font-weight: 600;
    text-align: justify;
}

.our-offer__list-text {
    padding:30px;   
}

.our-offer__link {
    text-decoration: underline;
    color: white;
}
