@import '../../../node_modules/@gsp/gusto-front-common/dist/scss/_reponsive.scss';

@media ($tablet) {
    .landing {
        height: calc(100vh - 4rem - 1px);
        display: flex;
    }
}

.landing {
    width: 100%;
    /* 4rem for the header, 1px for the header separator */
    align-items: flex-start;
    flex-direction: row-reverse;
}

@media ($mobile-only) {
    .landing {
        height: auto;
        display: contents;
    }
}


@media ($mobile-only) {
    .landing {
        flex-direction: column;
    }
}


.landing-description {
    flex: 1;
    max-height: calc(100vh - 4rem - 1px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

@media ($mobile-only) {
    .landing-description {
        max-height: none;
    }
}

.landing-quotation {
    flex: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    overflow: auto;
}

.landing__cover {
    width: 100%;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    position: relative;
    background: #2768A7;
    background-size: cover;
    background-image: url(../../resources/hero-banner.jpeg);
    background-position: 50% 60%;
    background-repeat: no-repeat;
}


.landing__header-line {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: white;
}

@media ($mobile-only) {
    .landing__title {
        font-size: 1.75em;
    }
}

.landing__title {
    color: white;
    font-size: 1.375rem;
    text-align: left;
}

.landing__logo {
    height: 80px;
    width: 80px;
}

.landing-quotation .stepper__body {
    flex: auto 0;
}
