@import '../../../../../node_modules/@gsp/gusto-front-common/dist/scss/reponsive';

.quotation-detail {
    width: 100%;
    max-width: 22rem !important;
}

.quotation-price {
    font-weight: bold;
    font-size: 28px;
}

.quotation-contact {
    display: flex;
    /* flex-grow: 1; */
    align-items: center;
    flex-direction: column;
    max-width: 400px;
    border-radius: 0.3rem;
    padding: 1rem;
    border: 1px solid #c5c5c5;
    background: rgba(236, 234, 229, 0.4);
}

@media ($mobile-only) {
    .landing-quotation div.quotation-contact {
        flex-direction: column;
        align-items: center;
        margin: 0rem 1rem;
    }
}

.quotation-contact-data {
    font-size: 16px;
}

.quotation-contact__field {
    width: 16rem;
    max-width: 100%;
}

.quotation-contact__privacy-notice {
    text-decoration: underline;
    color: #2768a7;
}

.quotation-contact-checkbox {
    display: flex;
    align-items: center;
    margin: 1rem 0;
    font-size: 12px;
}

.quotation-contact-checkbox label {
    margin-bottom: 0
}

.quotation-contact-disclaimer {
    display: flex;
    align-items: center;
    font-size: 11px;
}
